<template>
  <span>{{text}}</span>
</template>
<script>
import { marketOpenBets } from '@/configs/market.config'

export default {
  name: 'NumberType',
  props: ['type'],
  computed: {
    text() {
      const betType = marketOpenBets.find((bet)=>{
        return bet.code === this.type
      })

      if(betType) {
        return betType.text
      }else{
        return this.type
      }
    }
  }
}
</script>
