export const Roles = [
  {
    code: 'Agent',
    name: 'เอเย่นต์',
    class: 'badge badge-primary'
  },
  {
    code: 'Member',
    name: 'เมมเบอร์',
    class: 'badge badge-info'
  }
]
