export default {
  path: 'setting-report',
  component: { render (c) { return c('router-view') } },
  children: [
    {
      path: 'wait',
      name: 'WaitReward',
      component: () => import('@/views/setting-report/WaitReward')
    },
    {
      path: 'overall',
      name: 'RoundOverall',
      component: () => import('@/views/setting-report/RoundOverall')
    },
    {
      path: 'win-lose',
      name: 'RoundWinLose',
      component: () => import('@/views/setting-report/RoundWinLose')
    },
    {
      path: 'member',
      name: 'RoundMemberReport',
      component: () => import('@/views/setting-report/RoundMemberReport')
    },
    {
      path: 'tickets',
      name: 'RoundMemberTickets',
      component: () => import('@/views/setting-report/RoundMemberTickets')
    },
    {
      path: 'limit',
      name: 'RoundLimitNumbers',
      component: () => import('@/views/setting-report/RoundLimitNumbers')
    },
    {
      path: 'round-setting',
      name: 'RoundSetting',
      component: () => import('@/views/setting-report/RoundSetting')
    },
    {
      path: 'market-receive',
      name: 'MarketReceive',
      component: () => import('@/views/setting-report/MarketReceive')
    }
  ]
}
